import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import {
  Row,
  Col,
  Gap,
  Text,
  Section,
  Title,
  Button,
  VH,
  Fill,
  JobOffer,
  YouTube,
  Link,
  ImageOverlay,
  SEO,
  CareerTop,
} from '../components'
import { up, down, addPagePadding } from '../lib/styles'
import { extractNodesFromData } from '../lib/helpers'

import logo1 from '../data/images/career/logos/civil-aviation-logo.jpg'
import logo2 from '../data/images/career/logos/logo_CVUT.jpg'
import logo3 from '../data/images/career/logos/vzlu-logo.jpg'
import logo4 from '../data/images/career/logos/emrax-logo.jpg'
import logo5 from '../data/images/career/logos/mejzlik-logo.jpg'

import logo6 from '../data/images/career/logos/dassault-systemes-logo.jpg'
import logo7 from '../data/images/career/logos/technodat-logo.jpg'

import dividerImageSrc from '../data/images/career/divider.png'

import overlay1 from '../data/images/career/overlay1.png'
import overlay2 from '../data/images/career/overlay2.png'
import overlay3 from '../data/images/career/overlay3.png'

const PictureTitle = styled(Title.Big).attrs(() => ({
  bp: 'tablet',
}))`
  position: absolute;
  width: 1505px;
  max-width: 100%;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  z-index: 50;
  pointer-events: none;
  ${addPagePadding()}
  bottom: 15px;
  ${down('mobile')} {
    font-size: 24px;
    bottom: 10px;
  }

  ${up('tablet')} {
    bottom: 30px;
  }
`

const TopTitle = styled(Title.Big).attrs(() => ({
  bp: 'tablet',
}))`
  color: white;
  position: absolute;
  /* top: 14%; */
  /* right: 38%; */
  display: none;

  ${down('mobile')} {
    font-size: 24px;
  }

  ${up(420)} {
    display: block;
    top: 38%;
    right: 62%;
  }
`

const Divider = styled.img.attrs(() => ({
  src: dividerImageSrc,
}))`
  max-width: 100%;
  margin: 45px 0;
  height: 2px;
  ${up('tablet')} {
    height: auto;
  }
  ${addPagePadding()}
`

// const BoxTitle = styled(Title.Medium)`
//     position: absolute;
// `

const Box = styled.div`
  ${({ invertedBg }) => css`
    width: 100%;
    text-align: center;
    cursor: pointer;
    transition: 0.3s;
    font-size: 0;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

    padding: 20px;
    ${addPagePadding()}

    /* border: solid 1px #8bbea0; */

    /* #50f3ae  start*/
    /* #51f895 end*/
    /* #8bbea0 border */
    background: linear-gradient(to right, #50f3ae, #51f895);
    ${invertedBg
      && css`
        background: linear-gradient(to right, #51f895, #50f3ae);
      `}

    &:hover {
      background: linear-gradient(to right, #105ebc, #1e8ad1);
    }

    /* height: 180px; */
    min-height: 90px;
    height: 100%;
    ${up('mobile')} {
      /* height: 250px; */
      /* height: 90px; */
    }
    ${up('tablet')} {
      /* height: 315px; */
      /* height: 90px; */
    }
  `}
`

const OverlayImage = styled.img`
  opacity: 1;
  transition: 0.3s;
  z-index: 1;
  position: absolute;
  height: 100%;
  opacity: 0;
  &:hover {
    opacity: 0;
  }
  ${up('tablet')} {
    opacity: 0.4;
  }
  &:nth-child(1) {
    left: 0;
  }
  &:nth-child(2) {
    left: 50%;
    transform: translateX(-48.6%);
  }
  &:nth-child(3) {
    right: 0;
  }
`

const LogoImage = styled.img`
  max-width: 200px;
  padding: 0 0 40px 0;
  &:last-child {
    padding-bottom: 10px;
  }
  ${up('mobile')} {
    max-width: none;

    padding: 0 40px 50px 40px;
    &:last-child {
      padding-bottom: 50px;
    }
  }
`

class Career extends React.Component {
  constructor(props) {
    super(props)
    const {
      location: { hash },
    } = props
    this.state = {
      hash: hash && hash.replace('#', ''),
    }
  }

  setHash = (hash) => {
    const { pathContext: { lang } = {} } = this.props
    const url = lang === 'cs' ? Link.CAREER_CS : Link.CAREER
    window.history.replaceState('', '', `${url}#${hash}`)
    this.setState({ hash })
  }

  closePopup = () => this.setHash('')

  render() {
    const {
      location: { pathname },
      data: {
        topImage,
        planeImage,
        officeImage,
        endImage,
        thumbnail1,
        thumbnail2,
        topImage1,
        topImage2,
        topImage3,
        topImage4,
        ...restData
      } = {},
      pathContext: { lang } = {},
    } = this.props
    const texts = this.props.pathContext
    const { hash } = this.state
    const topImages = [topImage1, topImage2, topImage3, topImage4]

    let { offers } = extractNodesFromData(restData)
    // offers = offers.filter(offer => lang === offer.lang)
    if (lang === 'cs') {
      offers = offers.map(({ titleCs, contentCs, slugCs, slug, title, content, ...offer }) => ({
        ...offer,
        title: titleCs || title,
        content: contentCs || content,
        slug: slugCs || slug,
      }))
    }
    offers = offers.filter(({ title, content }) => !(!title || !content))

    const selectedOffer = offers.find(({ slug }) => slug === hash)

    const jobsRows = offers.reduce((result, job, key) => {
      if (key % 3 === 0) result[result.length] = []
      result[result.length - 1].push(job)
      return result
    }, [])

    return (
      <>
        <SEO
          title={texts.metaTitle}
          description="We are developing a modern private aircraft with vertical takeoff and landing. This will allow you to affordably travel from city to city at distances of up to 700 km apart."
          pathname={pathname}
        />

        <CareerTop images={topImages} />

        {selectedOffer && <JobOffer job={selectedOffer} onClose={this.closePopup} texts={texts} />}
        {!selectedOffer && (
          <div>
            <Section maxWidth="100%" width="1505px">
              <Gap gap="115px" mobileGap="50px" bp="tablet" />
              <Title.Big black textAlign="center">
                {texts.openPositions}
              </Title.Big>
            </Section>
            <Section withoutPagePadding>
              <Col width="895px" maxWidth="100%" margin="auto">
                <Gap gap="40px" mobileGap="40px" bp="tablet" />

                {jobsRows.map((row, rowKey) => (
                  <Fragment key={rowKey}>
                    <Row justifyContent="stretch" responsive bp="mobile">
                      {row.map((job, jobKey) => (
                        <Fragment key={job._id}>
                          <Col key={job._id} basis="32.6%">
                            {jobKey !== 0 && <Gap gap="0" mobileGap="10px" />}
                            <Box
                              invertedBg={jobKey % 3 === 1}
                              onClick={() => this.setHash(job.slug)}
                            >
                              <Title.Job bp="tablet">{job.title}</Title.Job>
                            </Box>
                          </Col>
                          {jobKey % 3 !== 2 && <Gap gap="10px" />}
                        </Fragment>
                      ))}
                    </Row>
                    <Gap gap="10px" mobileGap="20px" />
                  </Fragment>
                ))}
              </Col>
            </Section>

            <Section maxWidth="100%" width="1505px">
              <Gap gap="10px" />

              <Text black fontSize="22px" textAlign="center">
                {texts.otherJob}
                {' '}
                <a href="mailto:jobs@zuri.com">
                  {lang === 'cs' ? (
                    <Button.WriteUs>Napište&nbsp;nám</Button.WriteUs>
                  ) : (
                    <Button.WriteUs>Write&nbsp;us</Button.WriteUs>
                  )}
                </a>
              </Text>

              <Gap gap="115px" mobileGap="50px" bp="tablet" />

            </Section>

            <Section.Simple background="white">
              <Row justifyContent="stretch" responsive bp="tablet">
                <YouTube videoId="nzBGkX4EyKc" thumbnail={thumbnail1.image.fluid} />
                <Gap gap="5px" />
                <YouTube videoId="Gd5kn7B88Gk" thumbnail={thumbnail2.image.fluid} />
              </Row>
            </Section.Simple>

            <Section maxWidth="100%" width="1505px">
              <Gap gap="115px" mobileGap="50px" bp="tablet" />

              <Title.Big black textAlign="center">
                {texts.cooperation}
              </Title.Big>

              <Gap gap="40px" mobileGap="20px" />

              <Col alignItems="center">
                <Row
                  width="1200px"
                  maxWidth="100%"
                  justifyContent="space-around"
                  alignItems="center"
                  responsive
                  wrap="wrap"
                  mobileWrap="nowrap"
                >
                  <LogoImage src={logo1} alt="caa" />
                  <LogoImage src={logo2} alt="cvut" />
                  <LogoImage src={logo3} alt="vzlu" />
                  <LogoImage src={logo6} alt="dassault systemes" style={{ width: '370px' }} />
                  <LogoImage src={logo7} alt="technodat" style={{ width: '400px' }} />
                </Row>
              </Col>

              <Divider />

              <Title.Big black textAlign="center">
                {texts.production}
              </Title.Big>
              <Gap gap="40px" mobileGap="40px" />

              <Row
                justifyContent="center"
                alignItems="flex-end"
                mobileAlignItems="center"
                responsive
                wrap="wrap"
                mobileWrap="nowrap"
              >
                <LogoImage src={logo4} alt="emrax" />
                <LogoImage src={logo5} alt="mejzlik" />
              </Row>

              <Gap gap="40px" mobileGap="40px" bp="tablet" />
            </Section>
          </div>
        )}
      </>
    )
  }
}

export default Career

export const pageQuery = graphql`
  fragment ThumbnailImage on File {
    image: childImageSharp {
      fluid(maxWidth: 1000, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  query {
    topImage1: file(relativePath: { eq: "images/career/career-1.jpg" }) {
      ...FullHdImage
    }
    topImage2: file(relativePath: { eq: "images/career/career-2.jpg" }) {
      ...FullHdImage
    }
    topImage3: file(relativePath: { eq: "images/career/career-3.jpg" }) {
      ...FullHdImage
    }
    topImage4: file(relativePath: { eq: "images/career/career-4.jpg" }) {
      ...FullHdImage
    }

    thumbnail1: file(relativePath: { eq: "images/career/thumbnail3.jpg" }) {
      ...ThumbnailImage
    }
    thumbnail2: file(relativePath: { eq: "images/career/thumbnail4.jpg" }) {
      ...ThumbnailImage
    }

    topImage: file(relativePath: { eq: "images/career/top.jpg" }) {
      ...FullHdImage
    }

    endImage: file(relativePath: { eq: "images/career/end.jpg" }) {
      ...FullHdImage
    }

    planeImage: file(relativePath: { eq: "images/career/plane.jpg" }) {
      ...FullHdImage
    }

    officeImage: file(relativePath: { eq: "images/career/office.jpg" }) {
      ...FullHdImage
    }

    offers: allJobs(
      sort: { fields: sortOrder, order: ASC }
      filter: { state: { eq: "published" } }
    ) {
      edges {
        node {
          ...JobFragment
        }
      }
    }
  }
`
